import { css } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import AnswerItem from '@/components/AnswerItem';
import useStorageData from '@/useStorageData';
import Title from '@/components/Title';

/*
  Style
*/
const pageWrapperStyle = () => css`
  padding: 40px 20px 0px;

  & > div:first-of-type {
    margin-bottom: 40px;
  }
`;

const labelWrapperStyle = () => css`
  display: grid;
  gap: 16px;
`;

// Constants
const ANSWER_LIST: { label: string; isEtc?: boolean }[] = [
  {
    label: '가격이 합리적이에요',
  },
  {
    label: '데이터 소진 해도 빠른 속도로 쓸 수 있어요',
  },
  {
    label: '약정 없이도 쓸 수 있네요',
  },
  {
    label: '기타',
    isEtc: true,
  },
];

/*
  Component
*/
const Survey1LikePage = () => {
  // hooks
  const navigate = useNavigate();
  const { setLocalData } = useStorageData();

  // handler
  const onClick = (label: string) => {
    setLocalData(
      {
        answer1: {
          isLike: 'Y',
          answer: label,
        },
      },
      1,
    );
  };

  return (
    <section css={pageWrapperStyle}>
      <Title title="어떤 점이 마음에 드셨나요?" />
      <div css={labelWrapperStyle}>
        {ANSWER_LIST.map(({ label, isEtc }) => {
          return (
            <AnswerItem
              key={label}
              label={label}
              onClick={() => {
                if (isEtc) {
                  navigate(`/survey/1/form?select=like`);
                  return;
                }

                onClick(label);
              }}
            />
          );
        })}
      </div>
    </section>
  );
};

export default Survey1LikePage;
