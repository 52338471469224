import { css } from '@emotion/react';
import { Icon } from '@stage5/design-system';
import { themes } from '@/assets/themes';

/*
  Interface
*/
interface AnswerItemProps {
  label: string;
  onClick: () => void;
}

/*
  Style
*/
const itemBoxStyle = () => css`
  border-radius: 10px;
  background-color: ${themes.color['Gray/01']};
  padding: 16px 14px 16px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;

  & > p {
    ${themes.fontSize.Body2};
    ${themes.fontWeight.SemiBold};
    color: ${themes.color['Gray/15']};
    flex: 1;
    margin-right: 10px;
  }
`;

/*
  Component
*/
const AnswerItem = ({ label, onClick }: AnswerItemProps) => {
  return (
    <div css={itemBoxStyle} onClick={onClick}>
      <p>{label}</p>
      <Icon
        name="icChevron"
        rotate={90}
        width="24px"
        height="24px"
        color={themes.color['Gray/07']}
      />
    </div>
  );
};

export default AnswerItem;
