import { useNavigate } from 'react-router-dom';
import { css } from '@emotion/react';
import { Button } from '@stage5/design-system';
import useStorageData from '@/useStorageData';
import AnswerItem from '@/components/AnswerItem';
import Title from '@/components/Title';

/*
  Style
*/
const pageWrapperStyle = () => css`
  padding: 40px 20px 0px;

  & > div:first-of-type {
    margin-bottom: 40px;
  }
`;

const labelWrapperStyle = () => css`
  display: grid;
  gap: 16px;
`;

// Constants
const QUESTION_LIST = [
  { label: '합리적인 무제한 요금제', value: 1 },
  { label: '기본료를 최대한 낮춘 요금제', value: 2 },
  { label: '가족 통신비 낮출 수 있는 요금제', value: 3 },
  { label: '필요할 때만 무제한으로 쓰는 요금제', value: 4 },
];

/*
  Component
*/
const SurveyPage = () => {
  // hooks
  const navigate = useNavigate();
  const { localData } = useStorageData();

  // handler
  const onClick = (value: number) => {
    navigate(`/survey/${value}/1`);
  };

  // variables
  const isAnswer = (localData?.answerList || []).length;

  return (
    <>
      <section css={pageWrapperStyle}>
        <Title
          title={isAnswer ? `다른 요금제도\n살펴보시겠어요?` : `어떤 요금제에\n관심 있으세요?`}
        />
        <div css={labelWrapperStyle}>
          {QUESTION_LIST.map(({ label, value }) => {
            const isView = !localData?.answerList?.includes(value);

            if (!isView) {
              return null;
            }

            return (
              <AnswerItem
                key={label}
                label={label}
                onClick={() => {
                  onClick(value);
                }}
              />
            );
          })}
        </div>
      </section>
      {isAnswer ? (
        <Button.Full
          fixedPosition={{ bottom: 0 }}
          onClick={() => {
            navigate('/opinion');
          }}
        >
          아니요, 충분히 봤어요
        </Button.Full>
      ) : null}
    </>
  );
};

export default SurveyPage;
