import { Button } from '@stage5/design-system';
import { useNavigate } from 'react-router-dom';
import { css } from '@emotion/react';
import { themes } from '@/assets/themes';
import { fadeInUpAnimation } from '@/animation';

/*
  Style
*/
const pageWrapperStyle = () => css`
  padding-top: 194px;
  min-height: calc(100vh - 56px);
  display: flex;
  flex-direction: column;
  align-items: center;

  & > h3 {
    ${themes.fontSize.Heading3};
    ${themes.fontWeight.Bold};
    color: ${themes.color['Gray/15']};
    text-align: center;
    white-space: pre-wrap;

    ${fadeInUpAnimation};
  }
`;

const textInformationBoxStyle = () => css`
  display: grid;
  margin-top: 28px;
  border-radius: 10px;
  background-color: ${themes.color['Gray/01']};
  padding: 24px 40px 22px;
  ${fadeInUpAnimation};

  & > p {
    ${themes.fontSize.Body2};
    ${themes.fontWeight.Regular};
    color: ${themes.color['Gray/12']};
    text-align: center;
    white-space: pre-wrap;
  }

  & > h3 {
    ${themes.fontSize.Heading3};
    ${themes.fontWeight.Bold};
    color: ${themes.color['Gray/15']};
    margin: 6px 0px 16px;
    text-align: center;
    white-space: pre-wrap;
  }

  & > span {
    ${themes.fontSize.Body5};
    ${themes.fontWeight.Light};
    color: ${themes.color['Gray/10']};
    text-align: center;
  }
`;

/*
  Component
*/
const Intro7Page = () => {
  // hooks
  const navigate = useNavigate();

  return (
    <>
      <section css={pageWrapperStyle}>
        <h3>요즘 통신 요금은?</h3>
        <div css={textInformationBoxStyle}>
          <p>국민 1인당 평균 통신비</p>
          <h3>월 6만 5천원</h3>
          <span>{`<출처 : 한국소비자원 온라인 설문조사>`}</span>
        </div>
      </section>
      <Button.Full
        fixedPosition={{ bottom: 0 }}
        onClick={() => {
          navigate('/intro/8');
        }}
      >
        다음
      </Button.Full>
    </>
  );
};

export default Intro7Page;
