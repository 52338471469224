import { Route, Routes } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import Container from './components/Container';
import ErrorBoundary from './components/ErrorBoundary';
import NotFoundPage from './pages/NotFoundPage';
import SurveyPage from './pages/SurveyPage';
import useStorageData from './useStorageData';
import Intro1Page from './pages/Intro/Intro1Page';
import Intro2Page from './pages/Intro/Intro2Page';
import Intro3Page from './pages/Intro/Intro3Page';
import Intro4Page from './pages/Intro/Intro4Page';
import Intro5Page from './pages/Intro/Intro5Page';
import Intro6Page from './pages/Intro/Intro6Page';
import Intro7Page from './pages/Intro/Intro7Page';
import Intro8Page from './pages/Intro/Intro8Page';
import Survey1Question1Page from './pages/Survey1/Survey1Question1Page';
import Survey1Question2Page from './pages/Survey1/Survey1Question2Page';
import Survey1Question3Page from './pages/Survey1/Survey1Question3Page';
import Survey1LikePage from './pages/Survey1/Survey1LikePage';
import Survey1HatePage from './pages/Survey1/Survey1HatePage';
import Survey1FormPage from './pages/Survey1/Survey1FormPage';
import Survey2Question1Page from './pages/Survey2/Survey2Question1Page';
import Survey2Question2Page from './pages/Survey2/Survey2Question2Page';
import Survey2Question3Page from './pages/Survey2/Survey2Question3Page';
import Survey2Question4Page from './pages/Survey2/Survey2Question4Page';
import Survey2LikePage from './pages/Survey2/Survey2LikePage';
import Survey2HatePage from './pages/Survey2/Survey2HatePage';
import Survey2FormPage from './pages/Survey2/Survey2FormPage';
import Survey3Question1Page from './pages/Survey3/Survey3Question1Page';
import Survey3Question2Page from './pages/Survey3/Survey3Question2Page';
import Survey3LikePage from './pages/Survey3/Survey3LikePage';
import Survey3HatePage from './pages/Survey3/Survey3HatePage';
import Survey3FormPage from './pages/Survey3/Survey3FormPage';
import Survey4Question1Page from './pages/Survey4/Survey4Question1Page';
import Survey4Question2Page from './pages/Survey4/Survey4Question2Page';
import Survey4LikePage from './pages/Survey4/Survey4LikePage';
import Survey4HatePage from './pages/Survey4/Survey4HatePage';
import Survey4FormPage from './pages/Survey4/Survey4FormPage';
import OpinionPage from './pages/OpinionPage';
import ThankYouPage from './pages/ThankYouPage';
import SupportersPage from './pages/SupportersPage';
import FinishPage from './pages/FinishPage';

const App = () => {
  // hooks
  useStorageData();

  return (
    <RecoilRoot>
      <ErrorBoundary>
        <Routes>
          <Route element={<Container />}>
            {/* Intro Route */}
            <Route index element={<Intro1Page />} />
            <Route path="/intro/2" element={<Intro2Page />} />
            <Route path="/intro/3" element={<Intro3Page />} />
            <Route path="/intro/4" element={<Intro4Page />} />
            <Route path="/intro/5" element={<Intro5Page />} />
            <Route path="/intro/6" element={<Intro6Page />} />
            <Route path="/intro/7" element={<Intro7Page />} />
            <Route path="/intro/8" element={<Intro8Page />} />

            {/* Survey Route */}
            <Route path="/survey" element={<SurveyPage />} />

            {/* Survey1 Route */}
            <Route path="/survey/1/1" element={<Survey1Question1Page />} />
            <Route path="/survey/1/2" element={<Survey1Question2Page />} />
            <Route path="/survey/1/3" element={<Survey1Question3Page />} />
            <Route path="/survey/1/like" element={<Survey1LikePage />} />
            <Route path="/survey/1/hate" element={<Survey1HatePage />} />
            <Route path="/survey/1/form" element={<Survey1FormPage />} />

            {/* Survey2 Route */}
            <Route path="/survey/2/1" element={<Survey2Question1Page />} />
            <Route path="/survey/2/2" element={<Survey2Question2Page />} />
            <Route path="/survey/2/3" element={<Survey2Question3Page />} />
            <Route path="/survey/2/4" element={<Survey2Question4Page />} />
            <Route path="/survey/2/like" element={<Survey2LikePage />} />
            <Route path="/survey/2/hate" element={<Survey2HatePage />} />
            <Route path="/survey/2/form" element={<Survey2FormPage />} />

            {/* Survey3 Route */}
            <Route path="/survey/3/1" element={<Survey3Question1Page />} />
            <Route path="/survey/3/2" element={<Survey3Question2Page />} />
            <Route path="/survey/3/like" element={<Survey3LikePage />} />
            <Route path="/survey/3/hate" element={<Survey3HatePage />} />
            <Route path="/survey/3/form" element={<Survey3FormPage />} />

            {/* Survey4 Route */}
            <Route path="/survey/4/1" element={<Survey4Question1Page />} />
            <Route path="/survey/4/2" element={<Survey4Question2Page />} />
            <Route path="/survey/4/like" element={<Survey4LikePage />} />
            <Route path="/survey/4/hate" element={<Survey4HatePage />} />
            <Route path="/survey/4/form" element={<Survey4FormPage />} />

            {/* finish Route */}
            <Route path="/opinion" element={<OpinionPage />} />
            <Route path="/thank-you" element={<ThankYouPage />} />
            <Route path="/supporters" element={<SupportersPage />} />
            <Route path="/finish" element={<FinishPage />} />

            <Route path="*" element={<NotFoundPage />} />
          </Route>
        </Routes>
      </ErrorBoundary>
    </RecoilRoot>
  );
};

export default App;
