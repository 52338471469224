import { Button } from '@stage5/design-system';
import { useNavigate } from 'react-router-dom';
import TextIntro from '@/components/TextIntro';

/*
  Style
*/

/*
  Component
*/
const Survey2Question1Page = () => {
  // hooks
  const navigate = useNavigate();

  return (
    <>
      <section>
        <TextIntro
          label="카톡, 유튜브, 인터넷만 하는데"
          title="기본료 왜 이리 비싸죠?"
          help="이런 요금제 어떠세요?"
        />
      </section>
      <Button.Full
        fixedPosition={{ bottom: 0 }}
        onClick={() => {
          navigate('/survey/2/2');
        }}
      >
        기본료를 최대한 낮춘 요금제
      </Button.Full>
    </>
  );
};

export default Survey2Question1Page;
