import { Button } from '@stage5/design-system';
import { useNavigate } from 'react-router-dom';
import { css } from '@emotion/react';
import Image from '@/components/Image';
import { themes } from '@/assets/themes';
import { fadeInUpAnimation } from '@/animation';

/*
  Style
*/
const pageWrapperStyle = () => css`
  padding-top: 44px;
  min-height: calc(100vh - 56px);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const textInformationBoxStyle = () => css`
  display: grid;
  gap: 20px;
  margin-top: 60px;
  ${fadeInUpAnimation};

  & > h3 {
    ${themes.fontSize.Title3};
    ${themes.fontWeight.Bold};
    color: ${themes.color['Gray/15']};
    text-align: center;
    white-space: pre-wrap;
  }

  & > p {
    ${themes.fontSize.Body2};
    ${themes.fontWeight.Regular};
    color: ${themes.color['Gray/10']};
    text-align: center;
    white-space: pre-wrap;
  }
`;

/*
  Component
*/
const Intro2Page = () => {
  // hooks
  const navigate = useNavigate();

  return (
    <>
      <section css={pageWrapperStyle}>
        <Image
          src="x_title_logo.png"
          alt="타이틀 로고 이미지"
          width="360px"
          height="245px"
          isAnimate
        />
        <div css={textInformationBoxStyle}>
          <h3>{`간단한 설문에 응답해주시면\n보다 나은 통신 서비스를 만들어보게요!`}</h3>
          <p>{`통신 요금제에 대한\n여러분의 생각이 궁금해요`}</p>
        </div>
      </section>
      <Button.Full
        fixedPosition={{ bottom: 0 }}
        onClick={() => {
          navigate('/intro/3');
        }}
      >
        설문 응답하기
      </Button.Full>
    </>
  );
};

export default Intro2Page;
