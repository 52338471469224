import { Button } from '@stage5/design-system';
import { useNavigate } from 'react-router-dom';
import TextIntro from '@/components/TextIntro';

/*
  Component
*/
const Intro8Page = () => {
  // hooks
  const navigate = useNavigate();

  return (
    <>
      <section>
        <TextIntro
          title={`우리의 관점으로\n새롭게 바꿔볼게요!`}
          help="설문에 응답하시려면 다음을 눌러주세요"
        />
      </section>
      <Button.Full
        fixedPosition={{ bottom: 0 }}
        onClick={() => {
          navigate('/survey');
        }}
      >
        다음
      </Button.Full>
    </>
  );
};

export default Intro8Page;
