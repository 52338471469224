import { css } from '@emotion/react';
import { fadeInUpAnimation } from '@/animation';

export interface ImageProps {
  src: string;
  alt: string;
  width?: string;
  height?: string;
  className?: string;
  isAnimate?: boolean;
}

const imageStyle = (width?: string, height?: string, isAnimate?: boolean) => css`
  width: ${width || 'auto'};
  height: ${height || 'auto'};
  ${isAnimate &&
  css`
    ${fadeInUpAnimation};
  `}
`;

const Image = ({ src, alt, width, height, className, isAnimate = false }: ImageProps) => {
  return (
    <img
      src={`https://shop-cdn.stagefive.io/stageX/survey/${src}`}
      alt={alt}
      className={className}
      css={imageStyle(width, height, isAnimate)}
    />
  );
};

export default Image;
