import { css } from '@emotion/react';
import { Button, Toast } from '@stage5/design-system';
import { useState } from 'react';
import { themes } from '@/assets/themes';
import Title from '@/components/Title';

/*
  Style
*/
const pageWrapperStyle = () => css`
  padding: 40px 20px 0px;

  & > div:first-of-type {
    margin-bottom: 40px;
  }
`;

/*
  Component
*/
const FinishPage = () => {
  // useState
  const [isToast, setIsToast] = useState<boolean>(false);

  // handler
  const onClick = async () => {
    setIsToast(true);

    await navigator.clipboard.writeText('https://master.d21u97137yc0d1.amplifyapp.com/');
  };

  return (
    <>
      <section css={pageWrapperStyle}>
        <Title
          subhead="스테이지엑스"
          title={`응답해주신 의견은 더 좋은 요금제와\n서비스 설계를 위해 활용할게요!`}
          subtitle={`친구에게 공유하고 \n더 좋아질 통신 요금제 혜택 알려주세요!`}
        />
      </section>
      <Toast
        isOpen={isToast}
        title="링크 복사 완료!"
        iconProps={{
          name: 'icCircleCheckFill',
          width: '24px',
          height: '24px',
          color: 'none',
          fillColor: themes.color.White,
        }}
        onClose={() => {
          setIsToast(false);
        }}
        autoHideDuration={2000}
      />
      <Button.Full
        fixedPosition={{ bottom: 0 }}
        onClick={() => {
          onClick();
        }}
      >
        친구에게 공유하기
      </Button.Full>
    </>
  );
};

export default FinishPage;
