import { Outlet } from 'react-router-dom';
import { Global, css } from '@emotion/react';
import emotionReset from 'emotion-reset';
import { Modal } from '@stage5/design-system';
import { modalInitValue, useModalState } from '@/recoil/modalState';
import { themes } from '@/assets/themes';
import Header from './Header';

const styles = css`
  ${emotionReset}

  button {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  input {
    margin: 0;
  }

  body {
    background-color: rgb(248, 249, 250);
    max-width: 500px;
    margin: 0 auto;
    min-height: 100%;
  }

  input,
  textarea,
  button,
  select,
  a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  html * {
    font-family: Pretendard, AppleSDGothicNeo, 'Apple SD Gothic Neo', 'Apple SD 산돌고딕 Neo',
      sans-serif;
    box-sizing: border-box;
  }

  main {
    background-color: ${themes.color.White};
    position: relative;
  }

  section {
    min-height: calc(100vh - 56px);
    padding-bottom: 94px !important;
  }
`;

/*
  Component
*/
const Container = () => {
  // hooks
  const [{ isOpen, title, children }, setModalState] = useModalState();

  // handler
  const onClose = () => {
    setModalState(modalInitValue);
  };

  return (
    <>
      <Global styles={styles} />
      <main>
        <Header />
        <Outlet />
        <Modal isOpen={isOpen} title={title} height="full" zIndex={200} onClose={onClose}>
          {children}
        </Modal>
      </main>
    </>
  );
};

export default Container;
