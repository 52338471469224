import { Button } from '@stage5/design-system';
import { useNavigate } from 'react-router-dom';
import { css } from '@emotion/react';
import Image from '@/components/Image';
import { themes } from '@/assets/themes';
import { fadeInUpAnimation } from '@/animation';

/*
  Style
*/
const pageWrapperStyle = () => css`
  padding-top: 44px;
  min-height: calc(100vh - 56px);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const textInformationBoxStyle = () => css`
  display: grid;
  gap: 16px;
  margin-top: 10px;
  ${fadeInUpAnimation};

  & > h2 {
    ${themes.fontSize.Heading2};
    ${themes.fontWeight.Bold};
    color: ${themes.color['Gray/15']};
    text-align: center;
    white-space: pre-wrap;
  }

  & > p {
    ${themes.fontSize.Body2};
    ${themes.fontWeight.Regular};
    color: ${themes.color['Gray/12']};
    text-align: center;
    white-space: pre-wrap;
  }

  & > span {
    ${themes.fontSize.Body4};
    ${themes.fontWeight.Regular};
    color: ${themes.color['Gray/10']};
    text-align: center;
  }
`;

/*
  Component
*/
const Intro3Page = () => {
  // hooks
  const navigate = useNavigate();

  return (
    <>
      <section css={pageWrapperStyle}>
        <Image src="coupon.png" alt="타이틀 로고 이미지" width="360px" height="245px" isAnimate />
        <div css={textInformationBoxStyle}>
          <h2>통신 요금 1개월 무료</h2>
          <p>{`설문 응답해주시면\n2025년 상반기 통신 서비스를 런칭할 때\n요금제 1개월 무료 혜택을 드릴게요.`}</p>
          <span>24.06.17 ~ 24.06.30</span>
        </div>
      </section>
      <Button.Full
        fixedPosition={{ bottom: 0 }}
        onClick={() => {
          navigate('/intro/4');
        }}
      >
        다음
      </Button.Full>
    </>
  );
};

export default Intro3Page;
