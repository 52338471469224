import { Button } from '@stage5/design-system';
import { useNavigate } from 'react-router-dom';
import TextIntro from '@/components/TextIntro';

/*
  Style
*/

/*
  Component
*/
const Survey1Question1Page = () => {
  // hooks
  const navigate = useNavigate();

  return (
    <>
      <section>
        <TextIntro
          label="통신 3사와 같은 무제한 요금제"
          title="왜 비싸게 쓰세요?"
          help="이런 요금제 어떠세요?"
        />
      </section>
      <Button.Full
        fixedPosition={{ bottom: 0 }}
        onClick={() => {
          navigate('/survey/1/2');
        }}
      >
        합리적인 무제한 요금제
      </Button.Full>
    </>
  );
};

export default Survey1Question1Page;
