import { useLocalStorage } from '@stage5/react-use';
import { v4 as uuidv4 } from 'uuid';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LOCAL_STORAGE_KEY, LocalStorageProps } from './storage';

const useStorageData = () => {
  // hooks
  const navigate = useNavigate();

  // useState
  const [data, setData, removeLocalData] = useLocalStorage<LocalStorageProps>(LOCAL_STORAGE_KEY);

  // handler
  const setLocalData = (_data: LocalStorageProps, answerNumber?: number) => {
    const answerList = data?.answerList || [];

    if (answerNumber && !answerList.includes(answerNumber)) {
      answerList.push(answerNumber);
    }

    const variables = { ...data, answerList, ..._data };
    setData(variables);

    if (answerNumber) {
      if (answerList.length === 4) {
        navigate('/opinion');
        return;
      }

      navigate('/survey');
    }
  };

  // useEffect
  useEffect(() => {
    if (!data) {
      const newUUID = uuidv4();
      setData({ uuid: newUUID, answerList: [] });
    }
  }, [data, setData]);

  return { localData: data, setLocalData, removeLocalData };
};

export default useStorageData;
