import { css } from '@emotion/react';
import Image, { ImageProps } from './Image';
import { themes } from '@/assets/themes';

/*
  Interface
*/
interface InterestCheckProps {
  head: string;
  title?: string;
  imageProps: ImageProps;
}

/*
  Style
*/
const interestCheckWrapperStyle = () => css`
  & > h4 {
    ${themes.fontSize.Title4};
    ${themes.fontWeight.Regular};
    color: ${themes.color['Gray/15']};
    margin-bottom: 10px;
  }

  & > h1 {
    ${themes.fontSize.Title1};
    ${themes.fontWeight.Bold};
    color: ${themes.color['Gray/15']};
  }
`;

const overrideImageStyle = () => css`
  margin: 86px auto 0px;
  display: block;
`;

/*
  Component
*/
const InterestCheck = ({
  head,
  title = '사용할 의향 있으신가요?',
  imageProps,
}: InterestCheckProps) => {
  return (
    <div css={interestCheckWrapperStyle}>
      <h4>{head}</h4>
      <h1>{title}</h1>
      <Image
        src={imageProps.src}
        alt={imageProps.alt}
        width="200px"
        height="200px"
        css={overrideImageStyle}
      />
    </div>
  );
};

export default InterestCheck;
