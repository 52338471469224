import { AppType } from './data';

interface AnswerProps {
  isLike: 'Y' | 'N';
  answer?: string;
}

interface Answer2Props extends AnswerProps {
  favoriteAppList: AppType[];
}

export interface LocalStorageProps {
  uuid?: string;
  answer1?: AnswerProps;
  answer2?: Answer2Props;
  answer3?: AnswerProps;
  answer4?: AnswerProps;
  answerList?: number[];
  opinion?: string;
  isSupporters?: boolean;
}

export const LOCAL_STORAGE_KEY = 'stageX-survey';
