import { css } from '@emotion/react';
import { Button, Textarea } from '@stage5/design-system';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import useStorageData from '@/useStorageData';
import Title from '@/components/Title';

/*
  Style
*/
const pageWrapperStyle = () => css`
  padding: 40px 20px 0px;

  & > div:first-of-type {
    margin-bottom: 20px;
  }
`;

/*
  Component
*/
const Survey1FormPage = () => {
  // useState
  const [value, setValue] = useState<string>('');
  const { setLocalData } = useStorageData();

  // hooks
  const [searchParams] = useSearchParams();

  // variables
  const isLike = searchParams.get('select');

  return (
    <>
      <section css={pageWrapperStyle}>
        <Title title="기타 의견을 입력해주세요." />
        <Textarea
          placeholder="의견을 자유롭게 적어주세요!"
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
      </section>
      <Button.Full
        fixedPosition={{ bottom: 0 }}
        onClick={() => {
          setLocalData(
            {
              answer1: {
                isLike: isLike === 'like' ? 'Y' : 'N',
                answer: value,
              },
            },
            1,
          );
        }}
      >
        다음
      </Button.Full>
    </>
  );
};

export default Survey1FormPage;
