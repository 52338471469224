import { css } from '@emotion/react';
import { ReactNode, useMemo } from 'react';
import { Icon } from '@stage5/design-system';
import { themes } from '@/assets/themes';

type TooltipSize = 'small' | 'medium';
type EdgePosition =
  | 'topRight'
  | 'topCenter'
  | 'topLeft'
  | 'bottomRight'
  | 'bottomCenter'
  | 'bottomLeft'
  | 'none';

interface TooltipProps {
  edgePosition: EdgePosition;
  size: TooltipSize;
  title?: ReactNode | string;
  content: ReactNode | string;
  width?: string;
  onClose: () => void;
}

const containerStyle = (edgePosition: EdgePosition) => css`
  z-index: 100;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: ${edgePosition.includes('Center')
    ? 'center'
    : edgePosition.includes('Right')
    ? 'flex-end'
    : 'flex-start'};
`;

const tooltipBoxStyle = (size: TooltipSize, width?: string) => css`
  position: relative;
  margin: -1px 0;
  width: ${width || (size === 'small' ? '249px' : '300px')};
  background-color: ${themes.color['Gray/15']};
  padding: ${size === 'small' ? '12px 44px 12px 16px' : '18px 62px 18px 20px'};
  border-radius: 10px;
`;

const titleStyle = (size: TooltipSize) => css`
  ${size === 'small' ? themes.fontSize.Body3 : themes.fontSize.Body1};
  ${themes.fontWeight.SemiBold};
  color: ${themes.color.White};
  margin-bottom: ${size === 'small' ? '6px' : '11px'};
`;

const contentStyle = (size: TooltipSize) => css`
  ${size === 'small' ? themes.fontSize.Body4 : themes.fontSize.Body2};
  ${themes.fontWeight.Regular};
  color: ${themes.color.White};
  word-break: keep-all;
`;

const closeButtonStyle = (size: TooltipSize) => css`
  background-color: transparent;
  position: absolute;
  top: ${size === 'small' ? 12 : 18}px;
  right: ${size === 'small' ? 12 : 18}px;
  cursor: pointer;
`;

const triangleStyle = (size: TooltipSize) => css`
  margin: ${size === 'small' ? '0 26px' : '0 36px'};
`;

const Tooltip = ({ size, edgePosition, title, content, width, onClose }: TooltipProps) => {
  const onClickClose = () => onClose();

  const triangleWidth = useMemo(() => (size === 'small' ? '14px' : '21px'), [size]);
  const triangleHeight = useMemo(() => (size === 'small' ? '8px' : '12px'), [size]);

  return (
    <div css={containerStyle(edgePosition)}>
      {edgePosition.includes('top') && (
        <Icon
          style={triangleStyle(size)}
          name="icTooltipTriangle"
          width={triangleWidth}
          height={triangleHeight}
        />
      )}
      <div css={tooltipBoxStyle(size, width)}>
        <button type="button" css={closeButtonStyle(size)} onClick={onClickClose}>
          <Icon
            name="icClosed"
            width={size === 'small' ? '16px' : '24px'}
            height={size === 'small' ? '16px' : '24px'}
            color={themes.color.White}
          />
        </button>
        {typeof title === 'string' && !!title ? <p css={titleStyle(size)}>{title}</p> : title}
        {typeof content === 'string' && !!content ? (
          <p css={contentStyle(size)}>{content}</p>
        ) : (
          content
        )}
      </div>
      {edgePosition.includes('bottom') && (
        <Icon
          style={triangleStyle(size)}
          name="icTooltipTriangle"
          width={triangleWidth}
          height={triangleHeight}
          rotate={180}
        />
      )}
    </div>
  );
};

export default Tooltip;
