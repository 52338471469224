import { Button } from '@stage5/design-system';
import { useNavigate } from 'react-router-dom';
import { css } from '@emotion/react';
import Image from '@/components/Image';
import { themes } from '@/assets/themes';
import Tooltip from '@/components/Tooltip';
import { fadeInUpAnimation } from '@/animation';

/*
  Style
*/
const pageWrapperStyle = () => css`
  padding-top: 100px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const textInformationBoxStyle = () => css`
  display: grid;
  gap: 16px;
  margin-top: 60px;
  ${fadeInUpAnimation};

  & > p {
    ${themes.fontSize.Title2};
    ${themes.fontWeight.Bold};
    color: ${themes.color['Gray/15']};
    text-align: center;
  }
`;

const tooltipBoxStyle = () => css`
  display: flex;
  justify-content: center;
  padding-bottom: 8px;
  position: absolute;
  bottom: 64px;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 10;

  & > div > div:first-of-type {
    width: 100%;
    padding: 5px 16px 5px 16px;
    background-color: ${themes.color.Pindirect_blue};
    border-radius: 5px;

    & > button {
      display: none;
    }
  }

  & > div > div:last-of-type > svg > path {
    fill: ${themes.color.Pindirect_blue};
  }
`;

/*
  Component
*/
const Intro1Page = () => {
  // hooks
  const navigate = useNavigate();

  return (
    <>
      <section css={pageWrapperStyle}>
        <Image
          src="x_title_logo.png"
          alt="타이틀 로고 이미지"
          width="360px"
          height="245px"
          isAnimate
        />
        <div css={textInformationBoxStyle}>
          <p>신규 이동통신사</p>
          <p>스테이지엑스는 지금</p>
          <p>파격적인 통신서비스를</p>
          <p>준비하고 있어요!</p>
        </div>
      </section>
      <div css={tooltipBoxStyle}>
        <Tooltip
          edgePosition="bottomCenter"
          size="small"
          content="3분이면 끝나요!"
          onClose={() => {}}
        />
      </div>
      <Button.Full
        fixedPosition={{ bottom: 0 }}
        onClick={() => {
          navigate('/intro/2');
        }}
      >
        다음
      </Button.Full>
    </>
  );
};

export default Intro1Page;
