import { Button } from '@stage5/design-system';
import { useNavigate } from 'react-router-dom';
import TextIntro from '@/components/TextIntro';

/*
  Style
*/

/*
  Component
*/
const Survey3Question1Page = () => {
  // hooks
  const navigate = useNavigate();

  return (
    <>
      <section>
        <TextIntro
          label="온 가족 통신비 20만원?"
          title="아깝지 않으세요?"
          help="이런 요금제 어떠세요?"
        />
      </section>
      <Button.Full
        fixedPosition={{ bottom: 0 }}
        onClick={() => {
          navigate('/survey/3/2');
        }}
      >
        가족 통신비 낮추는 요금제
      </Button.Full>
    </>
  );
};

export default Survey3Question1Page;
