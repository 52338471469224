import { css } from '@emotion/react';
import { themes } from '@/assets/themes';
import { fadeInUpAnimation } from '@/animation';

/*
  Interface
*/
interface TextIntroProps {
  label?: string;
  title: string;
  help: string;
}

/*
  Style
*/
const introPageStyle = () => css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: calc(100vh - 56px - 94px);

  & > p {
    ${themes.fontSize.Title4};
    ${themes.fontWeight.Medium};
    color: ${themes.color['Gray/10']};
    text-align: center;
    white-space: pre-wrap;
    ${fadeInUpAnimation};
  }

  & > h2 {
    margin-top: 8px;
    ${themes.fontSize.Heading2};
    ${themes.fontWeight.Bold};
    color: ${themes.color['Gray/15']};
    text-align: center;
    white-space: pre-wrap;
    ${fadeInUpAnimation};
  }

  & > span {
    position: absolute;
    bottom: -10.5px;
    ${themes.fontSize.Body2};
    ${themes.fontWeight.Regular};
    color: ${themes.color['Gray/09']};
  }
`;

/*
  Component
*/
const TextIntro = ({ label, title, help }: TextIntroProps) => {
  return (
    <div css={introPageStyle}>
      {label && <p>{label}</p>}
      <h2>{title}</h2>
      <span>{help}</span>
    </div>
  );
};

export default TextIntro;
