import { css } from '@emotion/react';

export const fadeInUpAnimation = css`
  @keyframes fadeInUp {
    from {
      transform: translateY(12px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  transform: translateY(12px);
  opacity: 0;
  animation-name: fadeInUp;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  animation-delay: 0.25s;
  animation-duration: 0.43s;
`;
