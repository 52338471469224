/* eslint-disable jsx-a11y/control-has-associated-label */
import { css } from '@emotion/react';
import { Icon } from '@stage5/design-system';
import { useLocation, useNavigate } from 'react-router-dom';
import { themes } from '@/assets/themes';

/*
  Style
*/
const basicHeaderWrapperStyle = () => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 56px;
  background-color: ${themes.color.White};
  position: sticky;
  top: 0;

  h4 {
    color: ${themes.color['Gray/15']};
    ${themes.fontSize.Title4};
    ${themes.fontWeight.Bold};
  }

  button {
    cursor: pointer;
    background-color: transparent;
  }
`;

/*
  Component
*/
const Header = () => {
  // hooks
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // variables
  const isNotViewHeader = pathname === '/' || pathname === '/finish';

  // handler
  const onClick = () => {
    navigate(-1);
  };

  if (isNotViewHeader) {
    return null;
  }

  return (
    <header css={basicHeaderWrapperStyle}>
      <button type="button" onClick={onClick}>
        <Icon
          name="icBack_2"
          color={themes.color['Gray/15']}
          width="24px"
          height="24px"
          strokeWidth={2}
        />
      </button>
    </header>
  );
};

export default Header;
