import { css } from '@emotion/react';
import { Button, Textarea } from '@stage5/design-system';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useStorageData from '@/useStorageData';
import Title from '@/components/Title';

/*
  Style
*/
const pageWrapperStyle = () => css`
  padding: 40px 20px 0px;

  & > div:first-of-type {
    margin-bottom: 20px;
  }
`;

/*
  Component
*/
const OpinionPage = () => {
  // hooks
  const navigate = useNavigate();

  // useState
  const [value, setValue] = useState<string>('');
  const { setLocalData } = useStorageData();

  // handler
  const onClick = () => {
    setLocalData({
      opinion: value,
    });
    navigate('/thank-you');
  };

  return (
    <>
      <section css={pageWrapperStyle}>
        <Title title={`제 4 이동통신사 스테이지엑스에 \n하고 싶은 말을 남겨주세요.`} />
        <Textarea
          placeholder="예) 스테이지엑스 요금제 더 빨리 써보고 싶어요!"
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
      </section>
      <Button.Full fixedPosition={{ bottom: 0 }} onClick={onClick}>
        다음
      </Button.Full>
    </>
  );
};

export default OpinionPage;
