import { css } from '@emotion/react';
import { Button } from '@stage5/design-system';
import { useNavigate } from 'react-router-dom';
import Title from '@/components/Title';
import { themes } from '@/assets/themes';

/*
  Style
*/
const pageWrapperStyle = () => css`
  padding: 40px 20px 0px;
`;

const planDescriptionBoxStyle = () => css`
  display: grid;
  margin-top: 71px;

  & > p {
    ${themes.fontSize.Body1};
    ${themes.fontWeight.Medium};
    color: ${themes.color['Gray/15']};
    text-align: center;
    white-space: pre-wrap;
  }

  & > div {
    margin: 22px 0px 20px;
    border-radius: 10px;
    background-color: ${themes.color['Gray/01']};
    padding: 20px 0px;
    display: grid;
    gap: 8px;
    justify-content: center;

    & > h2 {
      ${themes.fontSize.Title2};
      ${themes.fontWeight.SemiBold};
      color: ${themes.color['Gray/15']};
      text-align: center;
      white-space: pre-wrap;
    }
  }

  & > span {
    ${themes.fontSize.Body5};
    ${themes.fontWeight.Light};
    color: ${themes.color['Gray/09']};
    text-align: center;
    white-space: pre-wrap;
  }
`;

/*
  Component
*/
const Survey1Question2Page = () => {
  // hooks
  const navigate = useNavigate();

  return (
    <>
      <section css={pageWrapperStyle}>
        <Title title={`통신3사의 무제한 요금제\n가격은 보통 이정도에요`} />
        <div css={planDescriptionBoxStyle}>
          <p>‘5G 데이터 100GB’ 요금제 가격</p>
          <div>
            <h2>SKT</h2>
            <h2>월 69,000원</h2>
          </div>
          <span>{`5G 데이터 100GB 요금제 기준\n기본 데이터 소진 시 5Mbps 데이터 사용`}</span>
        </div>
      </section>
      <Button.Full
        fixedPosition={{ bottom: 0 }}
        onClick={() => {
          navigate('/survey/1/3');
        }}
      >
        다음
      </Button.Full>
    </>
  );
};

export default Survey1Question2Page;
