import { Button } from '@stage5/design-system';
import { useNavigate } from 'react-router-dom';
import { css } from '@emotion/react';
import Image from '@/components/Image';
import { themes } from '@/assets/themes';
import { fadeInUpAnimation } from '@/animation';

/*
  Style
*/
const pageWrapperStyle = () => css`
  padding-top: 60px;
  min-height: calc(100vh - 56px);
  display: flex;
  flex-direction: column;
  align-items: center;

  & > h3 {
    ${themes.fontSize.Heading3};
    ${themes.fontWeight.Bold};
    color: ${themes.color['Gray/15']};
    margin-bottom: 50px;
    text-align: center;
    white-space: pre-wrap;
  }
`;

const textInformationBoxStyle = () => css`
  display: grid;
  margin-top: 40px;
  ${fadeInUpAnimation};

  & > span {
    ${themes.fontSize.Body3};
    ${themes.fontWeight.Bold};
    color: ${themes.color['Gray/12']};
    text-align: center;
  }

  & > h2 {
    ${themes.fontSize.Heading2};
    ${themes.fontWeight.Bold};
    color: ${themes.color['Gray/15']};
    margin: 6px 0px 20px;
    text-align: center;
    white-space: pre-wrap;
  }

  & > p {
    ${themes.fontSize.Body2};
    ${themes.fontWeight.Regular};
    color: ${themes.color['Gray/10']};
    text-align: center;
    white-space: pre-wrap;
  }
`;

/*
  Component
*/
const Intro6Page = () => {
  // hooks
  const navigate = useNavigate();

  return (
    <>
      <section css={pageWrapperStyle}>
        <h3>{`스테이지엑스가\n준비중인 서비스는`}</h3>
        <Image src="x_logo.png" alt="엑스 로고 이미지" width="360px" height="245px" />
        <div css={textInformationBoxStyle}>
          <span>eXpreimental</span>
          <h2>놀라운 경험</h2>
          <p>{`기존 통신 서비스와는 다르게\n쉽고, 편하고, 재미있는 서비스를 선보일거에요.`}</p>
        </div>
      </section>
      <Button.Full
        fixedPosition={{ bottom: 0 }}
        onClick={() => {
          navigate('/intro/7');
        }}
      >
        다음
      </Button.Full>
    </>
  );
};

export default Intro6Page;
