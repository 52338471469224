import { css } from '@emotion/react';
import { Button } from '@stage5/design-system';
import { useNavigate } from 'react-router-dom';
import Title from '@/components/Title';

/*
  Style
*/
const pageWrapperStyle = () => css`
  padding: 40px 20px 0px;

  & > div:first-of-type {
    margin-bottom: 40px;
  }
`;

/*
  Component
*/
const ThankYouPage = () => {
  // hooks
  const navigate = useNavigate();

  return (
    <>
      <section css={pageWrapperStyle}>
        <Title
          title="설문 참여에 감사드립니다."
          subtitle={`휴대폰 번호 입력하시면 통신 서비스\n런칭할 때 요금제 무료 혜택 보내드릴게요!`}
        />
      </section>
      <Button.Full
        fixedPosition={{ bottom: 0 }}
        onClick={() => {
          navigate('/supporters');
        }}
      >
        무료 혜택 받기
      </Button.Full>
    </>
  );
};

export default ThankYouPage;
