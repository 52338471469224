import { Button } from '@stage5/design-system';
import { useNavigate } from 'react-router-dom';
import TextIntro from '@/components/TextIntro';

/*
  Style
*/

/*
  Component
*/
const Survey4Question1Page = () => {
  // hooks
  const navigate = useNavigate();

  return (
    <>
      <section>
        <TextIntro
          label=" 24시간 쓰는것도 아닌데"
          title={`무제한 요금제\n최선일까요?`}
          help="이런 요금제 어떠세요?"
        />
      </section>
      <Button.Full
        fixedPosition={{ bottom: 0 }}
        onClick={() => {
          navigate('/survey/4/2');
        }}
      >
        필요할 때만 무제한 요금제
      </Button.Full>
    </>
  );
};

export default Survey4Question1Page;
