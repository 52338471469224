import { css } from '@emotion/react';
import { Button } from '@stage5/design-system';
import { useNavigate } from 'react-router-dom';
import useStorageData from '@/useStorageData';
import Title from '@/components/Title';

/*
  Style
*/
const pageWrapperStyle = () => css`
  padding: 40px 20px 0px;

  & > div:first-of-type {
    margin-bottom: 40px;
  }
`;

/*
  Component
*/
const SupportersPage = () => {
  // hooks
  const navigate = useNavigate();
  const { setLocalData, removeLocalData } = useStorageData();

  // handler
  const onClick = (isSupporters: boolean) => {
    setLocalData({ isSupporters });

    setTimeout(() => {
      removeLocalData();
    }, 1000);
    navigate('/finish');
  };

  return (
    <>
      <section css={pageWrapperStyle}>
        <Title title={`스테이지엑스\n서포터즈가 되어주세요!`} />
      </section>
      <Button.Full
        fixedPosition={{ bottom: 0 }}
        buttons={[
          {
            children: '아니요',
            onClick: () => {
              onClick(false);
            },
          },
          {
            children: '관심있어요',
            onClick: () => {
              onClick(true);
            },
          },
        ]}
      />
    </>
  );
};

export default SupportersPage;
