import { css } from '@emotion/react';
import { PageTitle, PageTitleProps } from '@stage5/design-system';
import { themes } from '@/assets/themes';

/*
  Style
*/
const overridePageTitleStyle = () => css`
  & > span {
    ${themes.fontSize.Title4};
    ${themes.fontWeight.Regular};
  }

  & > h2 {
    ${themes.fontSize.Title1};
    ${themes.fontWeight.Bold};
  }
`;

/*
  Component
*/
const Title = ({ subhead, title, subtitle }: PageTitleProps) => {
  return (
    <PageTitle
      subhead={subhead}
      title={title}
      subtitle={subtitle}
      style={overridePageTitleStyle()}
    />
  );
};

export default Title;
