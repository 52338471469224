import { css } from '@emotion/react';
import { Button } from '@stage5/design-system';
import { useNavigate } from 'react-router-dom';
import Title from '@/components/Title';

/*
  Style
*/
const pageWrapperStyle = () => css`
  padding: 40px 20px 0px;
`;

/*
  Component
*/
const Survey2Question2Page = () => {
  // hooks
  const navigate = useNavigate();

  return (
    <>
      <section css={pageWrapperStyle}>
        <Title title="평소 많이 사용하는 앱 5가지 골라주세요." />
      </section>
      <Button.Full
        fixedPosition={{ bottom: 0 }}
        onClick={() => {
          navigate('/survey/2/3');
        }}
      >
        선택 완료
      </Button.Full>
    </>
  );
};

export default Survey2Question2Page;
