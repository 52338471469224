import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

export interface ModalStateProps {
  isOpen: boolean;
  title: string; // 모달 헤더에 표시되는 제목
  children: React.ReactNode; // 모달 내에 표시 할 template
  onClose?: () => void;
}

export const modalInitValue: ModalStateProps = {
  isOpen: false,
  title: '',
  children: null,
};

const modalPropsState = atom<ModalStateProps>({
  key: 'modalPropsState',
  default: modalInitValue,
});

export const useModalState = () => useRecoilState(modalPropsState);
export const useModalSetState = () => useSetRecoilState(modalPropsState);
export const useModalValue = () => useRecoilValue(modalPropsState);
