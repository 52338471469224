import { css } from '@emotion/react';
import { Button } from '@stage5/design-system';
import { useNavigate } from 'react-router-dom';
import InterestCheck from '@/components/InterestCheck';

/*
  Style
*/
const pageWrapperStyle = () => css`
  padding: 40px 20px 0px;
`;

/*
  Component
*/
const Survey3Question2Page = () => {
  // hooks
  const navigate = useNavigate();

  return (
    <>
      <section css={pageWrapperStyle}>
        <InterestCheck
          head="가족 통신비 낮추는 요금제"
          imageProps={{ src: 'gray_dummy.png', alt: '그레이 더미 이미지' }}
        />
      </section>
      <Button.Full
        fixedPosition={{ bottom: 0 }}
        buttons={[
          {
            children: '아니요',
            onClick: () => {
              navigate('/survey/3/hate');
            },
          },
          {
            children: '네',
            onClick: () => {
              navigate('/survey/3/like');
            },
          },
        ]}
      />
    </>
  );
};

export default Survey3Question2Page;
